@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Smartphones (portrait and landscape) ----------- */
/*
P - portrait
L- landscape

devices breakpoints sneak-peak:
https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints

technique:
https://css-tricks.com/media-queries-sass-3-2-and-codekit/

usage example:

.class {
	@include breakpoint(phone-l) { width: 60%; }
	@include breakpoint(ipad-three-less) { width: 60%; }
}

*/
.pointer-events__disabled {
  pointer-events: none;
}
.pointer-events__enabled {
  pointer-events: auto;
}

.full-width {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .full-width {
    padding: 0;
  }
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.text-al {
  text-align: left;
}

.text-ar {
  text-align: right;
}

.bold {
  font-weight: 900;
}

.hidden {
  display: none;
}

.desktop {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .desktop {
    display: block;
  }
  .desktop.inline-block {
    display: inline-block;
  }
}

.mobile {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }
  .mobile.inline-block {
    display: inline-block;
  }
}

.col-xs-5th,
.col-sm-5th,
.col-md-5th,
.col-lg-5th {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs-5th {
  flex-basis: 20% !important;
  max-width: 20% !important;
}

@media (min-width: 768px) {
  .col-sm-5th {
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}
@media (min-width: 992px) {
  .col-md-5th {
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}
@media (min-width: 1200px) {
  .col-lg-5th {
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}
.article-cover {
  padding-bottom: 96px;
}
.article-cover figure {
  padding: 15px;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;
}
.article-cover figure img {
  display: block;
  width: 100%;
}
.article-cover figure svg {
  position: absolute;
  z-index: 1;
  display: block;
  width: 16.66%;
  height: auto;
  top: 49%;
  right: 15px;
}
.article-cover figcaption {
  padding-top: 45px;
  padding-bottom: 93px;
  padding-right: 16.66%;
  padding-left: 20px;
  box-sizing: border-box;
  position: relative;
}
.article-cover figcaption svg {
  position: absolute;
  z-index: 1;
  display: block;
  width: 20px;
  height: auto;
  top: 0;
  left: calc(33.34% - 20px);
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }

  .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }

  .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }

  .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}
@-webkit-keyframes topmenu-slide-down {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -357px;
  }
}
@keyframes topmenu-slide-down {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -357px;
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout-and-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes fadeout-and-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@-webkit-keyframes stretch {
  0% {
    width: calc(20% - 8px);
  }
  100% {
    width: 100%;
    left: 0;
  }
}
@keyframes stretch {
  0% {
    width: calc(20% - 8px);
  }
  100% {
    width: 100%;
    left: 0;
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@-webkit-keyframes shake-ie {
  0%, 100% {
    margin-top: -20px;
  }
  50% {
    margin-top: 0;
  }
}
@keyframes shake-ie {
  0%, 100% {
    margin-top: -20px;
  }
  50% {
    margin-top: 0;
  }
}
@-webkit-keyframes slideUp {
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slideUp {
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes rollOverUp {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 0 -38px;
  }
  51% {
    display: none;
    background-position: 0 38px;
  }
  52% {
    display: block;
    background-position: 0 38px;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes rollOverUp {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 0 -38px;
  }
  51% {
    display: none;
    background-position: 0 38px;
  }
  52% {
    display: block;
    background-position: 0 38px;
  }
  100% {
    background-position: 0 0;
  }
}
.fade-to-black {
  background-color: #000000 !important;
  color: #ffffff !important;
  transition: all 0.54s ease-in;
}
.fade-to-black a {
  color: #ffffff !important;
  transition: all 0.54s ease-in;
}
.fade-to-black h3, .fade-to-black ul, .fade-to-black p {
  background-color: #000000 !important;
  color: #ffffff !important;
  transition: all 0.54s ease-in;
}

@-webkit-keyframes slidein {
  0% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
            transform: rotate3d(0, 0, 1, 360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
            transform: rotate3d(0, 0, 1, 360deg);
  }
}
.falka-animate {
  display: block;
  position: relative;
  overflow: hidden;
}
.falka-animate svg {
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: rotate3d(0, 0, 1, 90deg);
          transform: rotate3d(0, 0, 1, 90deg);
  display: block;
}
.falka-animate.rotate svg {
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
  transition: -webkit-transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
}

.falka-animate-backwards {
  display: block;
  position: relative;
  overflow: hidden;
}
.falka-animate-backwards svg {
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
          transform: rotate3d(0, 0, 1, -90deg);
  display: block;
}
.falka-animate-backwards.rotate svg {
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
  transition: -webkit-transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
}

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after, .slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*# sourceMappingURL=slick.min.css.map */
/* usage example:
.class {
	@include breakpoint(phone-l) { width: 60%; }
}
*/
html {
  scroll-behavior: smooth;
}

body {
  background-color: #FBF8EE;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 24.59px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-weight: 400;
  display: block;
  color: #21499A;
  /* Smartphones (portrait and landscape) ----------- */
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  body {
    font-size: 16px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: #21499A;
}

header,
h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
}

.section-title {
  font-family: "Lato", sans-serif;
  word-spacing: 100vw;
  text-transform: lowercase;
}
.section-title__md {
  font-size: 36px;
  line-height: 32px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .section-title__md {
    font-size: 28px;
    line-height: 26px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .section-title__md {
    font-size: 28px;
    line-height: 26px;
  }
}
.section-title__xl {
  font-size: 72px;
  line-height: 58px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .section-title__xl {
    font-size: 52px;
    line-height: 39px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .section-title__xl {
    font-size: 48px;
    line-height: 36px;
  }
}

.mobile-menu-btn {
  position: absolute;
  z-index: 4;
  display: block;
  top: 24px;
  right: 16px;
  width: 39px;
  height: auto;
  cursor: pointer;
}
.mobile-menu-btn svg {
  display: block;
  width: 100%;
  height: auto;
}

.nav-mainmenu {
  background-color: #FBF8EE;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.nav-mainmenu__menu__item {
  display: inline-block;
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__menu__item {
    display: block;
    text-align: center;
  }
}
.nav-mainmenu__menu__item > a {
  position: relative;
  display: block;
  padding: 13px 18px;
  font-size: 20px;
  line-height: 1;
  font-weight: 900;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__menu__item > a {
    display: block;
    padding: 21px 18px;
    font-size: 24px;
    line-height: 1;
  }
}
.nav-mainmenu__menu__item > a svg {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 44px;
  pointer-events: none;
  z-index: 0;
  display: block;
}
.nav-mainmenu__menu__item > a svg path {
  fill: none;
  stroke: #21499A;
  stroke-width: 1px;
  shape-rendering: geometricPrecision;
  stroke-dasharray: 273.8151855469px;
  stroke-dashoffset: 273.8151855469px;
  -webkit-animation: drawDoodle 0.5s ease-in-out;
          animation: drawDoodle 0.5s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes drawDoodle {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawDoodle {
  100% {
    stroke-dashoffset: 0;
  }
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__menu__item > a {
    display: none !important;
  }
}
.nav-mainmenu__menu__item > div {
  display: none;
  position: absolute;
  top: 46px;
  left: 18px;
  width: -webkit-max-content;
  width: max-content;
  border: 1px solid #21499A;
  padding: 39px 17px;
  background-color: #FBF8EE;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__menu__item > div {
    position: static;
    display: block;
    width: 100%;
    border: none;
    padding: 0;
  }
}
.nav-mainmenu__menu__item > div > ul > li {
  border-bottom: 1px solid #21499A;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__menu__item > div > ul > li {
    border: none;
  }
}
.nav-mainmenu__menu__item > div > ul > li a:hover {
  text-decoration: underline;
}
.nav-mainmenu__menu__item > div > ul > li > a {
  font-weight: 700;
  display: block;
  margin-bottom: 0;
  padding-top: 8px;
}
.nav-mainmenu__menu__item > div > ul > li:last-child {
  border: none;
}
.nav-mainmenu__menu__item > div > ul > li:last-child > a {
  margin-bottom: 0;
}
.nav-mainmenu__menu__item > div > ul > li ul {
  padding-left: 21px;
  padding-bottom: 19px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__menu__item > div > ul > li ul {
    padding-left: 0;
  }
}
.nav-mainmenu__menu__item > div > ul > li ul li a {
  font-weight: 400;
}
.nav-mainmenu__menu__item:hover > div {
  display: block;
}
.nav-mainmenu__submenu {
  width: 50vw;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__submenu {
    width: auto;
  }
}
.nav-mainmenu__submenu__item a {
  font-size: 12px;
  line-height: 14.4px;
  text-transform: lowercase;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .nav-mainmenu__submenu__item a {
    font-size: 21px;
    line-height: 24px;
  }
}

.main-header {
  position: relative;
  padding: 54px 0 34px;
  z-index: 2;
  /* Smartphones (portrait and landscape) ----------- */
}
.main-header > .row {
  width: calc(100% + 1px);
}
@media only screen and (max-width: 768px) {
  .main-header {
    height: auto;
    max-height: 106px;
    overflow: hidden;
  }
  .main-header.expanded {
    max-height: 1600px;
    transition: max-height 0.66s ease-in-out;
  }
}
.main-header h1 {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .main-header h1 {
    padding-top: 8px;
  }
}
.main-header h1 img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 161px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-header h1 img {
    margin-top: 26px;
  }
}
.main-header .contact__data__emails {
  position: relative;
}
.main-header .contact__data__emails .contact__data__values {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .main-header .contact__data__emails .contact__data__values a {
    padding-left: 20px;
  }
}

.main-footer.boxed {
  margin-top: 85px;
  margin-bottom: 500px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer.boxed {
    margin-bottom: 0;
  }
}
.main-footer p, .main-footer a, .main-footer .contact__data__label {
  font-size: 20px;
  line-height: 24px;
  color: #21499A;
}
.main-footer a:hover {
  text-decoration: underline;
}
.main-footer__logo {
  position: relative;
}
.main-footer__logo.row {
  margin-bottom: 32px;
}
.main-footer__logo::after {
  position: absolute;
  bottom: 0;
  left: -1600px;
  content: "";
  display: block;
  width: 5000px;
  height: 1px;
  background-color: #21499A;
}
.main-footer__logo img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__logo img {
    width: auto;
    display: inline-block;
  }
}
.main-footer__contact-data.row {
  margin-bottom: 76px;
}
.main-footer__contact-data .bold {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__contact-data .bold {
    margin-bottom: 20px;
  }
}
.main-footer__contact-data__address p {
  padding-left: 20%;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__contact-data__address p {
    padding-left: 0;
  }
}
.main-footer .contact__data {
  margin-top: 0;
}
.main-footer__copyrights p {
  color: #22262d;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__copyrights p {
    font-size: 16px;
  }
}
.main-footer__copyrights__note.col {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__copyrights__note.col {
    margin-bottom: 16px;
  }
}
.main-footer__copyrights__line {
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__copyrights__line {
    margin-bottom: 32px;
  }
}
.main-footer__copyrights__line::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 3000px;
  height: 1px;
  background-color: #21499A;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__copyrights__line::after {
    left: -16px;
  }
}
.main-footer__legal.row {
  margin-top: 12px;
  margin-bottom: 32px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-footer__legal.row {
    margin-bottom: 23px;
    margin-top: 34px;
  }
}
.main-footer__legal a {
  font-size: 14px;
  line-height: 1;
  color: #22262d;
}

.App {
  overflow: hidden;
}

.page {
  position: relative;
  z-index: 1;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page {
    padding-top: 30px;
  }
}

.boxed {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 72px;
  padding-right: 72px;
  box-sizing: border-box;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .boxed {
    padding: 0 16px;
    box-sizing: border-box;
  }
}
.boxed.page {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .boxed.page {
    padding: 0;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .boxed {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.boxed > .row {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.section {
  display: block;
  min-height: 400px;
  width: 100%;
}

.row, .col {
  margin: 0;
  padding: 0;
}

.page.home {
  background-position: center 0;
}
.page.home > article {
  min-height: 300px;
}
.page.home .about-us {
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .about-us {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.page.home .about-us__blenda {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
.page.home .about-us__cover {
  display: block;
  width: 100%;
  height: auto;
  z-index: 0;
}
.page.home .about-us figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .about-us figcaption {
    position: relative;
  }
}
.page.home .about-us figcaption svg {
  margin-left: 50px;
  margin-bottom: 56px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .page.home .about-us figcaption svg {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 768px) {
  .page.home .about-us figcaption svg {
    margin-top: 80px;
    margin-bottom: 32px;
    margin-left: 0;
  }
}
.page.home .about-us__txt1 {
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  margin-left: 50px;
  margin-right: 34px;
  margin-bottom: 20px;
  max-width: 460px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .page.home .about-us__txt1 {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 768px) {
  .page.home .about-us__txt1 {
    font-size: 26px;
    line-height: 32px;
    margin-left: 26px;
    margin-right: 26px;
    max-width: unset;
  }
}
.page.home .about-us__txt2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  margin-left: 50px;
  margin-right: 50px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .about-us__txt2 {
    margin-bottom: 120px;
  }
}
.page.home .services__category__topbar {
  padding-top: 52px;
  padding-bottom: 52px;
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .services__category__topbar {
    padding-top: 88px;
    padding-bottom: 34px;
  }
}
.page.home .services__category__topbar::before, .page.home .services__category__topbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1500px;
  width: 6000px;
  height: 1px;
  background-color: #21499A;
}
.page.home .services__category__topbar::before {
  top: 0;
}
.page.home .services__category__topbar::after {
  bottom: 0;
}
.page.home .services__category__topbar h2, .page.home .services__category__topbar h3 {
  display: inline-block;
}
.page.home .services__category__topbar h2 {
  font-size: 40px;
  line-height: 48px;
}
.page.home .services__category__topbar h2::after {
  content: " / ";
}
.page.home .services__category__topbar h3 {
  font-size: 22px;
  line-height: 26.4px;
}
.page.home .services__category__topbar ul {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .services__category__topbar ul {
    margin-top: 16px;
  }
}
.page.home .services__category__topbar ul li {
  position: relative;
}
.page.home .services__category__topbar ul li span {
  display: block;
  padding-top: 18px;
  padding-bottom: 17px;
  text-align: center;
  cursor: pointer;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .services__category__topbar ul li span {
    padding-top: 10px;
    padding-bottom: 9px;
  }
}
.page.home .services__category__topbar ul li svg {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 44px;
  pointer-events: none;
  z-index: 0;
  display: block;
}
.page.home .services__category__topbar ul li svg path {
  fill: none;
  stroke: #21499A;
  stroke-width: 1px;
  shape-rendering: geometricPrecision;
  stroke-dasharray: 273.8151855469px;
  stroke-dashoffset: 273.8151855469px;
  -webkit-animation: drawDoodle 0.5s ease-in-out;
          animation: drawDoodle 0.5s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@keyframes drawDoodle {
  100% {
    stroke-dashoffset: 0;
  }
}
.page.home .services__category__item__info {
  color: #fefefe;
  background-color: #21499A;
}
.page.home .services__category__item__info__title h4 {
  padding: 12px 25px 15px;
}
.page.home .services__category__item__info article {
  padding: 0 10px 15px 25px;
}
.page.home .services__category__item__info > .row {
  border-top: 1px solid #fefefe;
}
.page.home .services__category__item__info > .row:first-child, .page.home .services__category__item__info > .row:nth-child(2) {
  border: none;
}
.page.home .services__category__item__info__description p {
  display: block;
  height: calc(3em + 8.2px);
  overflow: hidden;
}
.page.home .services__category__item__info__specs {
  overflow: visible;
  min-height: 0;
}
.page.home .services__category__item__info__specs article {
  display: block;
  height: calc(3em + 8.2px);
  overflow: hidden;
}
.page.home .services__category__item__info__specs .col:first-child {
  position: relative;
}
.page.home .services__category__item__info__specs .col:first-child::after {
  content: "";
  background-color: #fefefe;
}
.page.home .services__category__item__gallery {
  border-left: 18px solid #FBF8EE;
  border-top: none;
}
.page.home .gallery {
  position: relative;
  margin-top: 28.5px;
}
.page.home .gallery h2 {
  font-size: 40px;
  line-height: 1;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 31px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .gallery h2 {
    text-align: center;
  }
}
.page.home .gallery__blend {
  position: absolute;
  display: block;
  width: 100%;
  height: calc(100% - 70px);
  z-index: 1;
  pointer-events: none;
  top: 70px;
  left: 0;
}
.page.home .gallery .slick-list {
  z-index: 0;
}
.page.home .gallery .slick-arrow {
  position: absolute;
  display: block;
  z-index: 2;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 18px;
}
.page.home .gallery .slick-arrow.slick-next {
  left: auto;
  right: 18px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.post .main-footer {
  margin-top: 172px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .post .main-footer {
    margin-top: 1em;
  }
}

.overlay-loader {
  position: fixed;
  top: 0;
  margin-top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  z-index: 10000;
  color: #22262d;
}
.overlay-loader .el {
  display: block;
  position: absolute;
  top: 49vh;
  left: 0;
  right: 0;
  bottom: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.overlay-loader .el svg {
  display: inline-block;
  width: 60px;
  height: auto;
}

.slick-slider {
  position: relative;
}
.slick-slider .slick-slide {
  vertical-align: top;
  line-height: 1;
}
.slick-slider .slick-slide > div {
  padding: 0;
  margin: 0;
  vertical-align: top;
  line-height: 0;
}
.slick-slider .slick-track::before, .slick-slider .slick-track::after {
  display: none !important;
}
.slick-slider .slick-arrow {
  position: absolute;
  width: 18.5px;
  height: 49px;
  display: block;
  z-index: 1;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.slick-slider .slick-arrow svg {
  display: block;
  width: 100%;
  height: auto;
}
.slick-slider .slick-arrow.slick-next {
  right: 31px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.slick-slider .slick-arrow.slick-prev {
  left: 31px;
}
.slick-slider img {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0;
  outline: none;
}

.clients {
  padding-top: 200px;
  padding-bottom: 55px;
  min-height: 1px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 991px) {
  .clients {
    padding-top: 30px;
  }
}
.clients__item img {
  display: block;
  width: 100%;
  height: auto;
}
.clients .slick-slide img {
  width: 100%;
  height: auto;
  display: block;
}
.clients .slick-arrow {
  position: absolute;
  top: calc(50% - 26px);
  cursor: pointer;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .clients .slick-arrow {
    top: calc(50% - 22px);
  }
  .clients .slick-arrow svg {
    width: 18px;
    height: auto;
  }
}
.clients .slick-arrow.slick-prev {
  left: -120px;
  /* Smartphones (portrait and landscape) ----------- */
}
.clients .slick-arrow.slick-prev svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media only screen and (max-width: 768px) {
  .clients .slick-arrow.slick-prev {
    left: -9px;
  }
}
.clients .slick-arrow.slick-next {
  right: -120px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .clients .slick-arrow.slick-next {
    right: -9px;
  }
}

.contact__data {
  font-size: 12px;
  line-height: 14.4px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .contact__data {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .contact__data {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.contact__data__label {
  text-align: right;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .contact__data__label {
    text-align: center;
  }
}
.contact__data a {
  display: block;
  padding-left: 6px;
  white-space: nowrap;
}
.contact__data a:hover {
  text-decoration: underline;
}

.lang-switch {
  position: absolute;
  top: 11px;
  display: inline-block;
  right: 16px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .lang-switch {
    text-align: center;
    margin-bottom: 60px;
    margin-right: auto;
    display: block;
  }
}
.lang-switch__wrapper {
  text-align: end;
  width: 33px;
  position: relative;
}
.lang-switch ul {
  width: 33px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .lang-switch ul {
    max-height: 30px;
    overflow: hidden;
  }
  .lang-switch ul:hover {
    max-height: 100px;
  }
  .lang-switch ul:hover li {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .lang-switch ul {
    width: auto;
    display: inline-block;
  }
}
.lang-switch ul li {
  display: none;
  align-items: center;
  place-content: center;
  width: 33px;
  height: 30px;
  /* Smartphones (portrait and landscape) ----------- */
}
.lang-switch ul li.default {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .lang-switch ul li.default {
    display: flex;
  }
}
.lang-switch ul li a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  text-align: center;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .lang-switch ul li a {
    font-size: 17px;
    display: block;
    padding-top: 8px;
  }
}
.lang-switch ul li:hover {
  background-color: #22262d;
}
.lang-switch ul li:hover a {
  color: #fefefe;
  transition: all 0.16s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .lang-switch ul li {
    display: inline-block;
    width: 44px;
    height: 38px;
    border-right: 1px solid #22262d;
  }
  .lang-switch ul li:last-child {
    border: none;
  }
}

.next-section-nav {
  position: relative;
}
.next-section-nav::before {
  content: "";
  display: block;
  position: absolute;
  width: 6000px;
  left: -1500px;
  top: 0;
  height: 1px;
  background-color: #21499A;
}
.next-section-nav a {
  display: block;
}
.next-section-nav a.bounce {
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}
.next-section-nav a svg {
  display: inline-block;
  width: 49px;
  height: 18.5px;
  margin-bottom: 41.5px;
}
.next-section-nav span {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 900;
  margin-top: 47px;
}
.next-section-nav__slash {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .next-section-nav__slash {
    display: none;
  }
}

.services__category__topbar {
  padding-top: 52px;
  padding-bottom: 52px;
  background-color: #FBF8EE;
  position: relative;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .services__category__topbar {
    padding-top: 88px;
    padding-bottom: 34px;
  }
}
.services__category__topbar::before, .services__category__topbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1500px;
  width: 6000px;
  height: 1px;
  background-color: #21499A;
}
.services__category__topbar::before {
  top: 0;
}
.services__category__topbar::after {
  bottom: 0;
}
.services__category__topbar h2, .services__category__topbar h3 {
  display: inline-block;
}
.services__category__topbar h2 {
  font-size: 40px;
  line-height: 48px;
  text-transform: lowercase;
  font-weight: 600;
}
.services__category__topbar h2::after {
  content: "/";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.services__category__topbar h3 {
  font-size: 22px;
  line-height: 26.4px;
  font-weight: 600;
}
.services__category__topbar ul {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .services__category__topbar ul {
    margin-top: 16px;
  }
}
.services__category__topbar ul li {
  position: relative;
}
.services__category__topbar ul li span {
  display: block;
  padding-top: 18px;
  padding-bottom: 17px;
  text-align: center;
  cursor: pointer;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .services__category__topbar ul li span {
    padding-top: 10px;
    padding-bottom: 9px;
  }
}
.services__category__topbar ul li.active {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .services__category__topbar ul li.active svg {
    display: block !important;
  }
}
.services__category__topbar ul li svg {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 44px;
  pointer-events: none;
  z-index: 0;
  display: block;
}
.services__category__topbar ul li svg path {
  fill: none;
  stroke: #21499A;
  stroke-width: 1px;
  shape-rendering: geometricPrecision;
  stroke-dasharray: 273.8151855469px;
  stroke-dashoffset: 273.8151855469px;
  -webkit-animation: drawDoodle 0.5s ease-in-out;
          animation: drawDoodle 0.5s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@keyframes drawDoodle {
  100% {
    stroke-dashoffset: 0;
  }
}
.services__category__item__info {
  color: #21499A;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
}
.services__category__item__info__thumb {
  background-color: #fefefe;
  position: relative;
}
.services__category__item__info__thumb__partner {
  position: absolute;
  bottom: 16px;
  left: -12px;
  text-align: left;
  padding: 6px 25px 5px;
  background-color: #004F90;
  color: #fefefe;
}
.services__category__item__info img {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.services__category__item__info__title {
  position: relative;
}
.services__category__item__info__title h4 {
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding: 18px 25px 15px;
  text-transform: lowercase;
}
.services__category__item__info__title nav {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.services__category__item__info__title nav ul li {
  display: inline-block;
  margin-right: 10px;
}
.services__category__item__info__title nav ul li:last-child {
  margin-right: 0;
}
.services__category__item__info h5 {
  font-weight: 700;
  padding: 12px 25px 6px;
}
.services__category__item__info article {
  padding: 0 25px 80px;
}
.services__category__item__info article p {
  padding-bottom: 12px;
}
.services__category__item__info a {
  color: #fefefe;
}
.services__category__item__info > .row {
  border-top: 1px solid #21499A;
}
.services__category__item__info > .row:first-child {
  border: none;
}
.services__category__item__info__description {
  position: relative;
}
.services__category__item__info__description p {
  padding: 12px 25px 0;
  margin-bottom: 25px;
}
.services__category__item__info__description p + span {
  position: absolute;
  display: block;
  font-weight: 700;
  text-transform: lowercase;
  cursor: pointer;
  left: 25px;
  bottom: 6px;
  /* Smartphones (portrait and landscape) ----------- */
}
.services__category__item__info__description p + span:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .services__category__item__info__description p + span {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.services__category__item__info__specs {
  overflow: hidden;
  min-height: 290px;
  position: relative;
}
.services__category__item__info__specs .col {
  /* Smartphones (portrait and landscape) ----------- */
}
.services__category__item__info__specs .col:first-child {
  position: relative;
}
.services__category__item__info__specs .col:first-child::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 300px;
  background-color: #21499A;
}
@media only screen and (max-width: 768px) {
  .services__category__item__info__specs .col {
    border-bottom: 12px solid #21499A;
  }
}
.services__category__item__info__specs__partner .col {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .services__category__item__info__specs__partner .col {
    border-bottom-color: #a7a7a7;
  }
}
.services__category__item__info__specs article + span {
  position: absolute;
  display: block;
  font-weight: 700;
  text-transform: lowercase;
  cursor: pointer;
  right: 16px;
  bottom: -25px;
  /* Smartphones (portrait and landscape) ----------- */
}
.services__category__item__info__specs article + span:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .services__category__item__info__specs article + span {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.services__category__item__gallery {
  width: calc(100% - 18px);
  border-top: 56px solid #FBF8EE;
  border-left: 18px solid #FBF8EE;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .services__category__item__gallery {
    width: 100%;
    border: none !important;
  }
}

.offer-item__banner img, .offer-item__plan img {
  display: block;
  width: 100%;
  height: auto;
}
.offer-item__plan {
  margin-top: 47px !important;
}
.offer-item__plan img {
  border: 1px solid #21499A;
  width: 75%;
  margin: 0 auto;
}

.portfolio {
  position: relative;
  padding: 0 0 80px;
}
.portfolio::after, .portfolio::before {
  content: "";
  position: absolute;
  width: 6000px;
  left: -1500px;
  height: 1px;
  background-color: #21499A;
}
.portfolio::before {
  top: 0;
}
.portfolio::after {
  bottom: 0;
}
.portfolio__topbar {
  margin-top: 29.5px;
  margin-bottom: 60px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .portfolio__topbar {
    text-align: center;
    margin-bottom: 46px;
  }
}
.portfolio__topbar h2 {
  margin: 0;
  padding: 0;
}
.portfolio__topbar h2 span {
  display: inline-block;
  font-size: 40px;
  line-height: 1;
  font-weight: 600;
  text-transform: lowercase;
}
.portfolio__topbar h2 span::before {
  content: "&";
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}
.portfolio__topbar h2 span:first-child::before {
  display: none;
}
.portfolio__category h3 {
  font-size: 34px;
  line-height: 48px;
  font-weight: 600;
  margin-top: 36px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .portfolio__category h3 {
    font-size: 32px;
    line-height: 38px;
  }
}
.portfolio__category__item {
  position: relative;
  cursor: pointer;
}
.portfolio__category__item__thumb {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 50px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .portfolio__category__item__thumb {
    display: none !important;
  }
}
.portfolio__category__item:hover .portfolio__category__item__thumb {
  opacity: 1;
  transition: opacity 0.66s ease-in-out;
}
.portfolio__category__item__title {
  display: inline-block;
  font-size: 21px;
  line-height: 42px;
  font-weight: 600;
  /* Smartphones (portrait and landscape) ----------- */
}
.portfolio__category__item__title::after {
  content: "/";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .portfolio__category__item__title {
    display: block;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 6px;
  }
  .portfolio__category__item__title::after {
    content: "";
    display: none;
  }
}
.portfolio__category__item__meta {
  display: inline-block;
  font-size: 21px;
  line-height: 26.4px;
  font-weight: 300;
  /* Smartphones (portrait and landscape) ----------- */
}
.portfolio__category__item__meta::after {
  content: "/";
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.portfolio__category__item__meta:last-child::after {
  display: none;
}
@media only screen and (max-width: 768px) {
  .portfolio__category__item__meta {
    display: block;
  }
  .portfolio__category__item__meta::after {
    content: "";
    display: none;
  }
  .portfolio__category__item__meta:last-child {
    margin-bottom: 16px;
  }
}
.portfolio__category h3 {
  text-transform: lowercase;
  margin-bottom: 16px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .portfolio__category h3 {
    margin-bottom: 30px;
    font-weight: 900;
  }
}
